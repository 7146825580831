@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.resend-text-animation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.resend-loading-button {
  background: #ecebff;
  color: #8789ff;
  box-shadow: none !important;
  transform: none !important;
  width: 200px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-transform: capitalize;

  &:hover,
  &:focus,
  &:active {
    background: #ecebff;
    color: #8789ff;
  }
}

.registration-plan-pricing {
  display: inline-flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 5px;

  &--rate {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  &--tariff {
    font-size: 14px !important;
    color: #000 !important;
    margin-bottom: 5px !important;
  }

  &--edit-btn {
    min-height: 28px !important;
  }

  &--edit-btn:hover,
  &--edit-btn:focus,
  &--edit-btn:active {
    min-height: 28px !important;
  }
}
